import { get } from 'lodash';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/dist/src/private/types/ItemTypes';
import {
  createPageUrl,
  getAppData,
  getCategoryUrl,
  getImageUrl,
  getSchemaEnabled,
  SECTION_CATEGORY,
  getFullLanguageCode,
  getCategoriesMap,
  resolveId,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_SEO_PAGINATED_TITLE } from '@wix/communities-blog-experiments';
import { getPostsByCategoryIdAndPage } from '../../selectors/post-selectors';
import { getIsSitePropertiesLoaded } from '../../store/is-loaded/is-loaded-selectors';
import { fetchSiteProperties } from '../../store/site-properties/site-properties-actions';
import { getSiteProperties } from '../../store/site-properties/site-properties-selectors';
import {
  getSectionUrl,
  getPostPageSectionUrl,
} from '../../store/topology/topology-selectors';
import { getCategorySchema } from '../json-schema';
import { getPaginationItemData } from './get-pagination-item-data';
import { getQueryLocale } from '../../selectors/locale-selectors';
import {
  getEntityCount,
  getPaginationPageSize,
} from '../../selectors/pagination-selectors';
import { getMultilingualQueryParam } from '../get-multilingual-query-param';

const mapTranslationData = (translation, siteLanguages) => {
  const langCode = getFullLanguageCode(siteLanguages, translation.language);
  return {
    langCode,
    url: `${translation.url.base}${translation.url.path}`,
  };
};

const getTranslatedPagesData = (translations, siteLanguages = []) =>
  translations.map((translation) =>
    mapTranslationData(translation, siteLanguages),
  );

export const generateCategorySEOTags = async ({
  appConfig,
  sectionUrl,
  category,
  store,
  page,
  showPagination,
  t,
  multilingual,
}) => {
  let state = store.getState();
  const isMultilingualEnabled = Boolean(getQueryLocale(state));
  const isSEOPaginatedTitleEnabled = isExperimentEnabled(state, EXPERIMENT_SEO_PAGINATED_TITLE);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const categoryUrl = getCategoryUrl(
    sectionUrl,
    appConfig.categoryPath,
    category.slug,
  );
  const multilingualQueryParam = getMultilingualQueryParam(multilingual);
  const categoryUrlWithPage = `${createPageUrl(
    page,
    categoryUrl || getSectionUrl(state),
  )}${multilingualQueryParam}`;
  const title = appConfig.useCategoryMenuLabelForMetadataTitle
  ? category.menuLabel
  : category.label

  const itemData = {
    title: isSEOPaginatedTitleEnabled ? title : title || category.menuLabel,
    label: category.menuLabel,
    description: category.description,
    canonicalUrl: categoryUrlWithPage,
    items: { numberOfItems: category.postAmount },
    pagination: {
      totalPages: parseInt(Math.ceil(entityCount / pageSize), 10),
      currentPage: page,
    },
  };

  if (appConfig.isWP || getSchemaEnabled(state)) {
    if (!getIsSitePropertiesLoaded(state)) {
      await store.dispatch(fetchSiteProperties());
    }

    state = store.getState();
    itemData.structuredData = JSON.stringify(
      getCategorySchema({
        posts: getPostsByCategoryIdAndPage(state, resolveId(category), page),
        category,
        categoryMap: getCategoriesMap(state),
        appConfig,
        appData: getAppData(state),
        siteProperties: getSiteProperties(state),
        sectionUrl,
        postPageSectionUrl: getPostPageSectionUrl(state),
      }),
    );
  }

  const itemDataWithPagination = getPaginationItemData({
    itemData,
    state,
    url: categoryUrl,
    page,
    t,
    updateTitle: showPagination && !isSEOPaginatedTitleEnabled,
    multilingualQueryParam,
  });

  if (category.cover) {
    const { width, height } = category.cover;
    const image = {
      url: getImageUrl({
        imageHost: appConfig.imageHost,
        image: category.cover,
      }),
      width,
      height,
    };

    if (isMultilingualEnabled) {
      itemDataWithPagination.mainImage = {
        ...image,
        hasImage: true,
      };
    } else {
      itemDataWithPagination.image = image;
    }
  }

  if (isMultilingualEnabled) {
    itemDataWithPagination.language = getFullLanguageCode(
      multilingual.siteLanguages,
      category.language,
    );
    itemDataWithPagination.translatedPages = get(
      category,
      'translations.length',
    )
      ? getTranslatedPagesData(
          category.translations,
          multilingual.siteLanguages,
        )
      : [];
  }

  return {
    itemType: ITEM_TYPES.BLOG_CATEGORY,
    itemData: { category: itemDataWithPagination },
  };
};
